//#region imports
import {
  GetOneParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  CreateParams,
  DeleteParams,
  DeleteManyParams,
  GetOneResult,
  GetListResult,
  GetManyResult,
  GetManyReferenceResult,
  DataProviderResult,
  RaRecord,
  DeleteManyResult,
} from "ra-core";
import gql from "graphql-tag";
import * as queries from "../graphql/queries";
import { DocumentNode } from "graphql";

//#endregion imports

//#region types
export type Params =
  | GetOneParams
  | GetListParams
  | GetManyParams
  | GetManyReferenceParams
  | UpdateParams
  | UpdateManyParams
  | CreateParams
  | DeleteParams
  | DeleteManyParams;

export interface BaseResourceMapping {
  getQuery: (params: unknown) => DocumentNode;
  getVariables: (params: unknown) => Record<string, unknown>;
  parseResponse: (response: { data: unknown }) => DataProviderResult;
}

export interface ResourceMapping<
  RAParamType,
  GraphQLQueryVariablesType,
  GraphQLResponseType,
  RAResponseType,
> {
  getQuery: (params: RAParamType) => DocumentNode;
  getVariables: (params: RAParamType) => GraphQLQueryVariablesType;
  parseResponse: (response: { data: GraphQLResponseType }) => RAResponseType;
}

export interface SimpleResourceMapping<
  RAParamType,
  GraphQLQueryVariablesType,
  GraphQLResponseType,
  RAResponseType,
> {
  query: string | DocumentNode;
  getVariables: (params: RAParamType) => GraphQLQueryVariablesType;
  parseResponse: (response: { data: GraphQLResponseType }) => RAResponseType;
}

export type AnyResourceMapping<A, B, C, D> =
  | ResourceMapping<A, B, C, D>
  | SimpleResourceMapping<A, B, C, D>;
//#endregion types

//#region utilities
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isString(x: any): x is string {
  return typeof x === "string";
}

export function declareResourceMapping<A, B, C, D>(
  resourceMap: AnyResourceMapping<A, B, C, D>,
): ResourceMapping<A, B, C, D> {
  // translate from SimpleResourceMapping to ResourceMapping.
  if ("query" in resourceMap) {
    if (isString(resourceMap.query)) {
      const compiledQuery = gql(resourceMap.query);
      return {
        ...resourceMap,
        getQuery: (): DocumentNode => compiledQuery,
      };
    } else {
      return {
        ...resourceMap,
        getQuery: (): DocumentNode => resourceMap.query as DocumentNode,
      };
    }
  }

  return resourceMap;
}

export function declareGetOne<B, C>(
  resourceMap: AnyResourceMapping<GetOneParams, B, C, GetOneResult>,
): ResourceMapping<GetOneParams, B, C, GetOneResult> {
  return declareResourceMapping(resourceMap);
}

export function declareGetList<B, C>(
  resourceMap: AnyResourceMapping<GetListParams, B, C, GetListResult>,
): ResourceMapping<GetListParams, B, C, GetListResult> {
  return declareResourceMapping(resourceMap);
}

export function declareGetMany<B, C>(
  resourceMap: AnyResourceMapping<GetManyParams, B, C, GetManyResult>,
): ResourceMapping<GetManyParams, B, C, GetManyResult> {
  return declareResourceMapping(resourceMap);
}

export function declareDeleteMany<B, C>(
  resourceMap: AnyResourceMapping<DeleteManyParams, B, C, DeleteManyResult>,
): ResourceMapping<DeleteManyParams, B, C, DeleteManyResult> {
  return declareResourceMapping(resourceMap);
}

export function declareGetManyReference<B, C>(
  resourceMap: AnyResourceMapping<
    GetManyReferenceParams,
    B,
    C,
    GetManyReferenceResult
  >,
): ResourceMapping<GetManyReferenceParams, B, C, GetManyReferenceResult> {
  return declareResourceMapping(resourceMap);
}

export function getOneVariables(params: GetOneParams): { id: string } {
  return { id: params.id as string };
}

export function getManyVariables(params: GetManyParams): {
  filter: { ids: string[] };
} {
  return { filter: { ids: params.ids as string[] } };
}

export function deleteManyVariables(params: DeleteManyParams): {
  ids: string[];
} {
  return { ids: params.ids as string[] };
}

export function getPaginatedListVariables(params: GetListParams): {
  page: number | undefined;
  perPage: number | undefined;
  filter: GetListParams["filter"];
  sort: { field?: string; order?: string };
} {
  const vars = {
    page: params.pagination?.page,
    perPage: params.pagination?.perPage,
    filter: params.filter,
    sort: {},
  };
  if (params.sort) {
    vars.sort = {
      field: params.sort.field.split(".")[0],
      order: params.sort.order,
    };
  }

  return vars;
}

export function parseGetOneResponseFactory<
  T extends { [key: string]: unknown },
>(field: string) {
  return function (response: { data: T }): GetOneResult {
    if (response.data[field]) {
      return {
        data: response.data[field] as RaRecord,
      };
    } else {
      throw new Error("Failed to retrieve record");
    }
  };
}

export function parseGetListResponseFactory(field: string) {
  return function (response: {
    data: { [key: string]: unknown };
  }): GetListResult {
    if (response.data[field]) {
      const data = response.data[field] as {
        items: RaRecord[];
        totalItems: number;
      };
      return {
        data: data.items,
        total: data.totalItems || 0,
      };
    } else {
      throw new Error("Failed to retrieve record");
    }
  };
}

export function parseBareGetListResponseFactory(field: string) {
  return function (response: {
    data: { [key: string]: unknown };
  }): GetListResult {
    if (response.data[field]) {
      const records = response.data[field] as RaRecord[];
      return {
        data: records,
        total: records.length,
      };
    } else {
      throw new Error("Failed to retrieve record");
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOne<T extends keyof typeof queries>(queryName: T) {
  return declareGetOne({
    query: queries[queryName],
    getVariables: getOneVariables,
    parseResponse: parseGetOneResponseFactory(queryName),
  });
}

type ListVariables = ReturnType<typeof getPaginatedListVariables>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPaginatedList<T extends keyof typeof queries>(
  queryName: T,
  transformVariables?: (vars: ListVariables) => ListVariables,
) {
  return declareGetList({
    query: queries[queryName],
    getVariables: (params) => {
      const vars = getPaginatedListVariables(params);
      if (transformVariables) {
        return transformVariables(vars);
      }
      return vars;
    },
    parseResponse: parseGetListResponseFactory(queryName),
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getUnpaginatedList<T extends keyof typeof queries>(
  queryName: T,
) {
  return declareGetList({
    query: queries[queryName],
    getVariables: () => ({}),
    parseResponse: parseGetListResponseFactory(queryName),
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getBareList<T extends keyof typeof queries>(queryName: T) {
  return declareGetList({
    query: queries[queryName],
    getVariables: () => ({}),
    parseResponse: parseBareGetListResponseFactory(queryName),
  });
}
//#endregion utilities
