/**
 * This file is NOT AUTO-GENERATED!
 */
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: OrganizationFilter
    $perPage: Int
    $page: Int
  ) {
    listOrganizations(filter: $filter, perPage: $perPage, page: $page) {
      items {
        id
        name
      }
      totalItems
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      integrations {
        id
        organizationId
        name
        integrationTypeId
        isActive
        integrationTypeName
        metadataTypeId
        metadataSftpPrefix
        metadataType
        metadataDirection
        environment
        entityMatchRules {
          type
          value
        }
        startDate
        endDate
        ftpHostAddress
        ftpPort
        ftpUser
        ftpPath
        ftpMetadataPath
        apiHostAddress
        apiUser
      }
    }
  }
`;
export const listSiteErrors = /* GraphQL */ `
  query ListSiteErrors(
    $perPage: Int
    $page: Int
    $filter: SiteErrorFilter
    $sort: SiteErrorSort
  ) {
    listSiteErrors(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        created
        customerIntegration {
          id
          integrationTypeId
          name
        }
        environment
        etmfId
        foundByApi
        id
        investigationNotes
        investigationStatus
        isActive
        lastFileCreatedDate
        orgId
        piEmailAddress
        piFirstName
        piLastName
        piOrg
        proId
        protocolNumber
        reason
        responsible
        ssuApprovedDate
        ssuId
        ssuState
        submittingOrg
        terminatedDate
        updated
      }
      totalItems
    }
  }
`;
export const getSiteError = /* GraphQL */ `
  query GetSiteError($id: ID!) {
    getSiteError(id: $id) {
      created
      customerIntegration {
        id
        integrationTypeId
        name
      }
      environment
      etmfId
      foundByApi
      id
      investigationNotes
      investigationStatus
      isActive
      lastFileCreatedDate
      orgId
      piEmailAddress
      piFirstName
      piLastName
      piOrg
      proId
      protocolNumber
      reason
      responsible
      ssuApprovedDate
      ssuId
      siteId
      ssuState
      submittingOrg
      terminatedDate
      updated
      integration {
        etmfId
        metadataType
        metadataDirection
        environment
      }
      suggestions {
        etmfCountryId {
          id
          source
        }
        etmfProductId {
          id
          source
        }
        etmfStudyId {
          id
          source
        }
      }
    }
  }
`;
export const listUnconfiguredProtocols = /* GraphQL */ `
  query ListUnconfiguredProtocols(
    $perPage: Int
    $page: Int
    $filter: StudyErrorFilter
    $sort: StudyErrorSort
  ) {
    listUnconfiguredProtocols(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        companyAssignment
        created
        customerIntegration {
          id
          integrationTypeId
          name
        }
        environment
        etmfId
        foundByApi
        id
        integration {
          environment
          etmfId
          metadataDirection
          metadataType
        }
        investigationNotes
        investigationStatus
        isActive
        orgId
        proId
        projectState
        protocolNumber
        reason
        responsible
        submittedDate
        submittingOrg
        updated
      }
      totalItems
    }
  }
`;
export const getUnconfiguredProtocol = /* GraphQL */ `
  query GetUnconfiguredProtocol($id: ID!) {
    getUnconfiguredProtocol(id: $id) {
      companyAssignment
      created
      customerIntegration {
        id
        integrationTypeId
        name
      }
      environment
      etmfId
      foundByApi
      id
      integration {
        environment
        etmfId
        metadataDirection
        metadataType
      }
      investigationNotes
      investigationStatus
      isActive
      orgId
      proId
      protocolId
      projectState
      protocolNumber
      reason
      responsible
      submittedDate
      submittingOrg
      updated
    }
  }
`;
export const listCustomerCtmsRecords = /* GraphQL */ `
  query ListCustomerCtmsRecords(
    $studyNum: String
    $filter: CustomerCTMSFilter
  ) {
    listCustomerCTMSRecords(studyNum: $studyNum, filter: $filter) {
      items {
        id
        orgId
        etmfId
        created
        updated
        processed
        error
        sourceBucket
        sourceKey
        piFirstName
        piLastName
        ingestedData
      }
      totalItems
    }
  }
`;
export const listIntegrationConfigurations = /* GraphQL */ `
  query ListIntegrationConfigurations {
    listIntegrationConfigurations {
      etmfId
      metadataType
      metadataDirection
      environment
    }
  }
`;
export const getIntegrationConfiguration = /* GraphQL */ `
  query GetIntegrationConfiguration($id: ID!) {
    getIntegrationConfiguration(id: $id) {
      etmfId
      metadataType
      metadataDirection
      environment
    }
  }
`;
export const listIntegrationTypes = /* GraphQL */ `
  query ListIntegrationTypes($filter: IntegrationTypeFilter) {
    listIntegrationTypes(filter: $filter) {
      items {
        id
        name
      }
      totalItems
    }
  }
`;
export const getIntegrationType = /* GraphQL */ `
  query GetIntegrationType($id: ID!) {
    getIntegrationType(id: $id) {
      id
      name
    }
  }
`;
export const listSiteReasonTypes = /* GraphQL */ `
  query ListSiteReasonTypes($filter: SiteReasonTypeFilter) {
    listSiteReasonTypes(filter: $filter) {
      items {
        id
      }
      totalItems
    }
  }
`;
export const getSiteReasonType = /* GraphQL */ `
  query GetSiteReasonType($id: String!) {
    getSiteReasonType(id: $id) {
      id
    }
  }
`;
export const listStudyReasonTypes = /* GraphQL */ `
  query ListStudyReasonTypes($filter: StudyReasonTypeFilter) {
    listStudyReasonTypes(filter: $filter) {
      items {
        id
      }
      totalItems
    }
  }
`;
export const getStudyReasonType = /* GraphQL */ `
  query GetStudyReasonType($id: String!) {
    getStudyReasonType(id: $id) {
      id
    }
  }
`;
export const listSiteXrefs = /* GraphQL */ `
  query ListSiteXrefs(
    $perPage: Int!
    $page: Int!
    $filter: SiteXrefFilter
    $sort: SiteXrefSort
  ) {
    listSiteXrefs(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        created
        customerIntegration {
          id
          integrationTypeId
          name
        }
        etmfCountryId
        etmfId
        etmfProductId
        etmfSiteId
        etmfStudyId
        id
        initialTransferCompleteTimestamp
        isActive
        lastScheduledDate
        orgId
        piOrg
        proId
        protocolNumber
        ssuId
        submittingOrg
        txfrIncremental
        txfrInitial
        updated
      }
      totalItems
    }
  }
`;
export const listProXrefs = /* GraphQL */ `
  query ListProXrefs(
    $perPage: Int!
    $page: Int!
    $filter: ProXrefFilter
    $sort: ProXrefSort
  ) {
    listProXrefs(perPage: $perPage, page: $page, filter: $filter, sort: $sort) {
      items {
        created
        customerIntegration {
          id
          integrationTypeId
          name
        }
        etmfCountryId
        etmfId
        etmfProductId
        etmfStudyId
        id
        initialTransferCompleteTimestamp
        isActive
        lastScheduledDate
        orgId
        proId
        protocolNumber
        submittingOrg
        txfrIncremental
        txfrInitial
        updated
      }
      totalItems
    }
  }
`;
export const getSiteXref = /* GraphQL */ `
  query GetSiteXref($id: ID!) {
    getSiteXref(id: $id) {
      created
      customerIntegration {
        id
        integrationTypeId
        name
      }
      etmfCountryId
      etmfId
      etmfProductId
      etmfSiteId
      etmfStudyId
      id
      initialTransferCompleteTimestamp
      isActive
      lastScheduledDate
      orgId
      piOrg
      proId
      protocolNumber
      ssuId
      submittingOrg
      txfrIncremental
      txfrInitial
      updated
    }
  }
`;

export const getProXref = /* GraphQL */ `
  query GetProXref($id: ID!) {
    getProXref(id: $id) {
      created
      customerIntegration {
        id
        integrationTypeId
        name
      }
      etmfCountryId
      etmfId
      etmfProductId
      etmfStudyId
      id
      initialTransferCompleteTimestamp
      isActive
      lastScheduledDate
      orgId
      proId
      protocolNumber
      submittingOrg
      txfrIncremental
      txfrInitial
      updated
    }
  }
`;
export const listAuditTrails = /* GraphQL */ `
  query ListAuditTrails(
    $perPage: Int
    $page: Int
    $filter: AuditTrailFilter
    $sort: AuditTrailSort
  ) {
    listAuditTrails(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        ... on SiteErrorAuditTrail {
          id
          sourceKey
          created
          updated
          proId
          ssuId
          investigationStatus
          protocolNumber
          piFirstName
          piLastName
        }
        ... on StudyErrorAuditTrail {
          id
          sourceKey
          created
          updated
          proId
          ssuId
          investigationStatus
          protocolNumber
          piFirstName
          piLastName
        }
        ... on CustomerCTMSRecordAuditTrail {
          id
          sourceKey
          created
          updated
          proId
          ssuId
          protocolNumber
          piFirstName
          piLastName
        }
        ... on SiteXrefAuditTrail {
          id
          sourceKey
          created
          updated
          proId
          ssuId

          user {
            username
            email
          }

          changeComment
        }
        ... on ProtocolXrefAuditTrail {
          id
          sourceKey
          created
          updated
          proId

          user {
            username
            email
          }

          changeComment
        }
      }
      totalItems
    }
  }
`;
export const getAuditTrail = /* GraphQL */ `
  query GetAuditTrail($id: ID!) {
    getAuditTrail(id: $id) {
      ... on SiteErrorAuditTrail {
        id
        sourceKey
        created
        updated
        user {
          username
          email
        }
        customerIntegration {
          id
          integrationTypeId
          name
        }
        proId
        ssuId
        investigationStatus
        protocolNumber
        piFirstName
        piLastName
        before {
          id
          orgId
          proId
          ssuState
          piFirstName
          piLastName
          piEmailAddress
          protocolNumber
          ssuApprovedDate
          terminatedDate
          lastFileCreatedDate
          investigationStatus
          foundByApi
          reason
          responsible
          investigationNotes
          created
          updated
        }
        after {
          id
          orgId
          proId
          ssuState
          piFirstName
          piLastName
          piEmailAddress
          protocolNumber
          ssuApprovedDate
          terminatedDate
          lastFileCreatedDate
          investigationStatus
          foundByApi
          reason
          responsible
          investigationNotes
          created
          updated
        }
      }
      ... on StudyErrorAuditTrail {
        id
        sourceKey
        created
        updated
        user {
          username
          email
        }
        customerIntegration {
          id
          integrationTypeId
          name
        }
        proId
        ssuId
        investigationStatus
        protocolNumber
        piFirstName
        piLastName
        before {
          id
          orgId
          projectState
          protocolNumber
          foundByApi
          submittedDate
          companyAssignment
          investigationStatus
          reason
          responsible
          investigationNotes
          created
          updated
        }
        after {
          id
          orgId
          projectState
          protocolNumber
          foundByApi
          submittedDate
          companyAssignment
          investigationStatus
          reason
          responsible
          investigationNotes
          created
          updated
        }
      }
      ... on CustomerCTMSRecordAuditTrail {
        id
        sourceKey
        created
        updated
        user {
          username
          email
        }
        customerIntegration {
          id
          integrationTypeId
          name
        }
        proId
        ssuId
        protocolNumber
        piFirstName
        piLastName
        before {
          id
          orgId
          etmfId
          created
          updated
          processed
          error
          sourceBucket
          sourceKey
          piFirstName
          piLastName
          ingestedData
        }
        after {
          id
          orgId
          etmfId
          created
          updated
          processed
          error
          sourceBucket
          sourceKey
          piFirstName
          piLastName
          ingestedData
        }
      }

      ... on SiteXrefAuditTrail {
        id
        sourceKey
        created
        user {
          username
          email
        }
        customerIntegration {
          id
          integrationTypeId
          name
        }
        proId
        ssuId

        changeComment

        before {
          orgId
          etmfId
          ssuId
          etmfSiteId
          etmfStudyId
          etmfProductId
          etmfCountryId
          transferInitial
          transferIncremental
          isActive
          initialTransferCompleteTimestamp
          lastScheduledDate
          id
        }

        after {
          orgId
          etmfId
          ssuId
          etmfSiteId
          etmfStudyId
          etmfProductId
          etmfCountryId
          transferInitial
          transferIncremental
          isActive
          initialTransferCompleteTimestamp
          lastScheduledDate
          id
        }
      }

      ... on ProtocolXrefAuditTrail {
        id
        sourceKey
        created
        user {
          username
          email
        }
        customerIntegration {
          id
          integrationTypeId
          name
        }
        proId

        changeComment

        before {
          orgId
          etmfId
          proId
          etmfStudyId
          etmfProductId
          etmfCountryId
          transferInitial
          transferIncremental
          isActive
          initialTransferCompleteTimestamp
          lastScheduledDate
          id
        }

        after {
          orgId
          etmfId
          proId
          etmfStudyId
          etmfProductId
          etmfCountryId
          transferInitial
          transferIncremental
          isActive
          initialTransferCompleteTimestamp
          lastScheduledDate
          id
        }
      }
    }
  }
`;
export const listErrors = /* GraphQL */ `
  query ListErrors(
    $perPage: Int
    $page: Int
    $filter: ErrorFilter
    $sort: ErrorSort
  ) {
    listErrors(perPage: $perPage, page: $page, filter: $filter, sort: $sort) {
      items {
        id
        assignee
        ticketNumber
        created
        updated
        resolved
        status
        sourceSys
        category
        message
        lambda
        stepFunction
        orgId
        integrationId
        transactionId
        documentId
        proId
        ssuId
        customerIntegrationId

        commentCount
        reoccuranceCount
      }
      totalItems
    }
  }
`;
export const getError = /* GraphQL */ `
  query GetError($id: ID!) {
    getError(id: $id) {
      id
      assignee
      ticketNumber
      created
      updated
      resolved
      status
      sourceSys
      category
      message
      lambda
      stepFunction
      orgId
      integrationId
      transactionId
      documentId
      proId
      ssuId
      comments {
        created
        username
        reoccurance
        note
      }
    }
  }
`;
export const listOrgIntegrations = /* GraphQL */ `
  query ListOrgIntegrations(
    $perPage: Int
    $page: Int
    $filter: OrgIntegrationFilter
    $sort: OrgIntegrationSort
  ) {
    listOrgIntegrations(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        organizationId
        name
        integrationTypeId
        isActive
        integrationTypeName
        metadataTypeId
        metadataSftpPrefix
        metadataType
        metadataDirection
        environment
        entityMatchRules {
          type
          value
        }
        startDate
        endDate
        ftpHostAddress
        ftpPort
        ftpUser
        ftpPath
        ftpMetadataPath
        apiHostAddress
        apiUser
      }
      totalItems
    }
  }
`;
export const getOrgIntegration = /* GraphQL */ `
  query GetOrgIntegration($id: ID!) {
    getOrgIntegration(id: $id) {
      id
      organizationId
      countryMapId
      customerMapId
      combineSitesInXrefsFlag
      name
      integrationTypeId
      isActive
      integrationTypeName
      metadataTypeId
      metadataSftpPrefix
      metadataType
      metadataDirection
      metadataVQLPull {
        enableVQLProtocolQuery
        enableVQLSiteQuery
        protocolVQL
        siteVQL
        protocolMapping {
          outputField
          vqlField
        }
        siteMapping {
          outputField
          vqlField
        }
        protocolLastModifiedTimestamp
        siteLastModifiedTimestamp
        sitePINameMatching
      }
      environment
      entityMatchRules {
        type
        value
      }
      startDate
      endDate
      ftpHostAddress
      ftpPort
      ftpUser
      ftpPath
      ftpMetadataPath
      apiHostAddress
      apiUser
      hasPassword
      sourceId
    }
  }
`;

export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $perPage: Int
    $page: Int
    $filter: PermissionFilter
    $sort: PermissionSort
  ) {
    listPermissions(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        type
        resource
        role
        created
        updated
        id
      }
      totalItems
    }
  }
`;

export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      type
      resource
      role
      created
      updated
      id
    }
  }
`;

export const roles = /* GraphQL */ `
  query ListRoles {
    roles {
      id
      name
    }
  }
`;

export const listCustomerMaps = /* GraphQL */ `
  query ListCustomerMaps(
    $perPage: Int
    $page: Int
    $filter: CustomerMapsFilter
    $sort: CustomerMapsSort
  ) {
    listCustomerMaps(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        name
        reference
        hasActiveIntegrations
        description
        integrationType {
          id
          name
        }
        created
        updated
        createdBy {
          username
          email
        }
        lastUpdatedBy {
          username
          email
        }

        customerIntegrations {
          id
          isActive
        }
      }
      totalItems
    }
  }
`;
export const getCustomerMap = /* GraphQL */ `
  query GetCustomerMap($id: ID!) {
    getCustomerMap(id: $id) {
      id
      name
      description
      reference
      hasActiveIntegrations
      integrationType {
        id
        name
      }
      created
      updated
      createdBy {
        username
        email
      }
      lastUpdatedBy {
        username
        email
      }

      customerIntegrations {
        id
        isActive
      }

      schemas {
        id
        name
        description
        precedence
        type
        created
        updated

        fields {
          created
          destinationFieldName
          id
          source
          sourceType
          updated
        }

        rules {
          comparisonOperator
          created
          fieldName
          id
          updated
          value
        }
      }
    }
  }
`;

export const listJobs = /* GraphQL */ `
  query ListJobs($filter: JobsFilter, $sort: JobsSort) {
    listJobs(filter: $filter, sort: $sort) {
      items {
        description
        id
        schedule
        status
        updatedAt
      }
      totalItems
    }
  }
`;

export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $perPage: Int!
    $page: Int!
    $filter: DocumentFilter
    $sort: DocumentSort
  ) {
    listDocuments(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        created
        fileCreatedDate
        fileName
        fileProcessedDate
        protocolExternalId
        siteExternalId
        source
        updated

        transactions {
          id
          etmfName
          orgName
          created
          externalId
          status
          updated
        }
      }
      totalItems
    }
  }
`;

export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      additionalMetadata {
        key
        value
      }
      created
      entityExternalId
      entityType
      fileCreatedDate
      fileName
      fileProcessedDate
      protocolExternalId
      siteExternalId
      s3Key
      source
      updated

      transactions {
        attributes
        id
        etmfName
        orgName
        created
        externalId
        status
        updated
      }
    }
  }
`;

export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      attributes
      id
      created
      docId
      externalId
      status
      updated
    }
  }
`;

export const getDocumentDownload = /* GraphQL */ `
  query GetDocumentDownload($id: ID!) {
    getDocumentDownload: getDocument(id: $id) {
      id
      downloadUrl
    }
  }
`;

export const listSupportUsers = /* GraphQL */ `
  query ListSupportUsers {
    listSupportUsers {
      id
      username
      email
    }
  }
`;

export const listCountryMaps = /* GraphQL */ `
  query ListCountryMaps(
    $perPage: Int
    $page: Int
    $filter: CountryMapsFilter
    $sort: CountryMapsSort
  ) {
    listCountryMaps(
      perPage: $perPage
      page: $page
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        name
        created
        updated
      }
      totalItems
    }
  }
`;
