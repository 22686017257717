const getOrgIntegration = /* GraphQL */ `
  query GetOrgIntegration($id: ID!) {
    getOrgIntegration(id: $id) {
      id
      organizationId
      countryMapId
      customerMapId
      combineSitesInXrefsFlag
      name
      integrationTypeId
      isActive
      integrationTypeName
      metadataTypeId
      metadataSftpPrefix
      metadataType
      metadataDirection
      metadataVQLPull {
        enableVQLProtocolQuery
        enableVQLSiteQuery
        protocolVQL
        siteVQL
        protocolMapping {
          outputField
          vqlField
        }
        siteMapping {
          outputField
          vqlField
        }
        protocolLastModifiedTimestamp
        siteLastModifiedTimestamp
        sitePINameMatching
      }
      environment
      entityMatchRules {
        type
        value
      }
      startDate
      endDate
      hasPassword
      ftpHostAddress
      ftpPort
      ftpUser
      ftpPath
      ftpMetadataPath
      apiHostAddress
      apiUser
      sourceId
    }
  }
`;

export default getOrgIntegration;
