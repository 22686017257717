"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatClientId = exports.formatOrgName = void 0;
function formatOrgName(orgName) {
    return orgName.replace(/[\W_]+/g, ".").substring(0, 50);
}
exports.formatOrgName = formatOrgName;
function formatClientId(orgName) {
    return `advarra-${formatOrgName(orgName)}-Connect-integration-vvga`;
}
exports.formatClientId = formatClientId;
