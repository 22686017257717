"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = exports.Ternary = exports.StudyErrorSortField = exports.SortOrder = exports.SortField = exports.SiteXrefSortField = exports.SiteSuggestionSource = exports.SitePiNameMatchingType = exports.SiteErrorSortField = exports.SchemaType = exports.SchemaRuleComparisonOperator = exports.SchemaFieldSourceType = exports.ReportDocumentStatusResultStatus = exports.ProXrefSortField = exports.PermissionType = exports.PermissionSortField = exports.MetadataDirection = exports.JobStatus = exports.ErrorSortField = exports.EntityType = exports.ERegInstanceEnvironment = exports.DocumentSortField = exports.CustomerEntityMatchRuleType = exports.CtmsRecordField = exports.AuditTrailSourceResource = void 0;
var AuditTrailSourceResource;
(function (AuditTrailSourceResource) {
    AuditTrailSourceResource["CustomerCtmsRecord"] = "CustomerCTMSRecord";
    AuditTrailSourceResource["ProtocolXref"] = "ProtocolXref";
    AuditTrailSourceResource["SiteError"] = "SiteError";
    AuditTrailSourceResource["SiteXref"] = "SiteXref";
    AuditTrailSourceResource["StudyError"] = "StudyError";
})(AuditTrailSourceResource = exports.AuditTrailSourceResource || (exports.AuditTrailSourceResource = {}));
var CtmsRecordField;
(function (CtmsRecordField) {
    CtmsRecordField["CountryId"] = "COUNTRY_ID";
    CtmsRecordField["EmailAddress"] = "EMAIL_ADDRESS";
    CtmsRecordField["PiFirstName"] = "PI_FIRST_NAME";
    CtmsRecordField["PiLastName"] = "PI_LAST_NAME";
    CtmsRecordField["PrincipalInvestigator"] = "PRINCIPAL_INVESTIGATOR";
    CtmsRecordField["ProductId"] = "PRODUCT_ID";
    CtmsRecordField["SiteId"] = "SITE_ID";
    CtmsRecordField["StudyCountry"] = "STUDY_COUNTRY";
    CtmsRecordField["StudyId"] = "STUDY_ID";
    CtmsRecordField["StudyNum"] = "STUDY_NUM";
})(CtmsRecordField = exports.CtmsRecordField || (exports.CtmsRecordField = {}));
var CustomerEntityMatchRuleType;
(function (CustomerEntityMatchRuleType) {
    CustomerEntityMatchRuleType["ProtocolSponsor"] = "ProtocolSponsor";
    CustomerEntityMatchRuleType["ProtocolSubmittingOrganization"] = "ProtocolSubmittingOrganization";
    CustomerEntityMatchRuleType["SitePiOrganization"] = "SitePIOrganization";
})(CustomerEntityMatchRuleType = exports.CustomerEntityMatchRuleType || (exports.CustomerEntityMatchRuleType = {}));
var DocumentSortField;
(function (DocumentSortField) {
    DocumentSortField["Created"] = "created";
    DocumentSortField["FileCreatedDate"] = "fileCreatedDate";
    DocumentSortField["FileName"] = "fileName";
    DocumentSortField["FileProcessedDate"] = "fileProcessedDate";
    DocumentSortField["OrgName"] = "orgName";
    DocumentSortField["ProtocolExternalId"] = "protocolExternalId";
    DocumentSortField["SiteExternalId"] = "siteExternalId";
    DocumentSortField["Source"] = "source";
    DocumentSortField["Updated"] = "updated";
})(DocumentSortField = exports.DocumentSortField || (exports.DocumentSortField = {}));
var ERegInstanceEnvironment;
(function (ERegInstanceEnvironment) {
    ERegInstanceEnvironment["NonProduction"] = "NON_PRODUCTION";
    ERegInstanceEnvironment["Production"] = "PRODUCTION";
})(ERegInstanceEnvironment = exports.ERegInstanceEnvironment || (exports.ERegInstanceEnvironment = {}));
var EntityType;
(function (EntityType) {
    EntityType["Cr"] = "CR";
    EntityType["Mod"] = "MOD";
    EntityType["Pre"] = "PRE";
})(EntityType = exports.EntityType || (exports.EntityType = {}));
var ErrorSortField;
(function (ErrorSortField) {
    ErrorSortField["Created"] = "created";
    ErrorSortField["Id"] = "id";
    ErrorSortField["ReoccuranceCount"] = "reoccuranceCount";
    ErrorSortField["Resolved"] = "resolved";
    ErrorSortField["Updated"] = "updated";
})(ErrorSortField = exports.ErrorSortField || (exports.ErrorSortField = {}));
var JobStatus;
(function (JobStatus) {
    JobStatus["Failed"] = "Failed";
    JobStatus["NotRunToday"] = "NotRunToday";
    JobStatus["Running"] = "Running";
    JobStatus["Succeeded"] = "Succeeded";
})(JobStatus = exports.JobStatus || (exports.JobStatus = {}));
var MetadataDirection;
(function (MetadataDirection) {
    MetadataDirection["Pull"] = "PULL";
    MetadataDirection["Push"] = "PUSH";
})(MetadataDirection = exports.MetadataDirection || (exports.MetadataDirection = {}));
var PermissionSortField;
(function (PermissionSortField) {
    PermissionSortField["Id"] = "id";
    PermissionSortField["Resource"] = "resource";
    PermissionSortField["Role"] = "role";
    PermissionSortField["Type"] = "type";
    PermissionSortField["Updated"] = "updated";
})(PermissionSortField = exports.PermissionSortField || (exports.PermissionSortField = {}));
var PermissionType;
(function (PermissionType) {
    PermissionType["Create"] = "create";
    PermissionType["List"] = "list";
    PermissionType["Update"] = "update";
})(PermissionType = exports.PermissionType || (exports.PermissionType = {}));
var ProXrefSortField;
(function (ProXrefSortField) {
    ProXrefSortField["Created"] = "created";
    ProXrefSortField["CustomerIntegration"] = "customerIntegration";
    ProXrefSortField["EtmfStudyId"] = "etmfStudyId";
    ProXrefSortField["IsActive"] = "isActive";
    ProXrefSortField["LastScheduledDate"] = "lastScheduledDate";
    ProXrefSortField["ProId"] = "proId";
    ProXrefSortField["ProtocolNumber"] = "protocolNumber";
    ProXrefSortField["SubmittingOrg"] = "submittingOrg";
})(ProXrefSortField = exports.ProXrefSortField || (exports.ProXrefSortField = {}));
var ReportDocumentStatusResultStatus;
(function (ReportDocumentStatusResultStatus) {
    ReportDocumentStatusResultStatus["Error"] = "ERROR";
    ReportDocumentStatusResultStatus["Success"] = "SUCCESS";
})(ReportDocumentStatusResultStatus = exports.ReportDocumentStatusResultStatus || (exports.ReportDocumentStatusResultStatus = {}));
var SchemaFieldSourceType;
(function (SchemaFieldSourceType) {
    SchemaFieldSourceType["Attribute"] = "ATTRIBUTE";
    SchemaFieldSourceType["Constant"] = "CONSTANT";
})(SchemaFieldSourceType = exports.SchemaFieldSourceType || (exports.SchemaFieldSourceType = {}));
var SchemaRuleComparisonOperator;
(function (SchemaRuleComparisonOperator) {
    SchemaRuleComparisonOperator["Contains"] = "CONTAINS";
    SchemaRuleComparisonOperator["Equals"] = "EQUALS";
    SchemaRuleComparisonOperator["GreaterThan"] = "GREATER_THAN";
    SchemaRuleComparisonOperator["GreaterThanOrEqual"] = "GREATER_THAN_OR_EQUAL";
    SchemaRuleComparisonOperator["IsNotNull"] = "IS_NOT_NULL";
    SchemaRuleComparisonOperator["IsNull"] = "IS_NULL";
    SchemaRuleComparisonOperator["LessThan"] = "LESS_THAN";
    SchemaRuleComparisonOperator["LessThanOrEqual"] = "LESS_THAN_OR_EQUAL";
    SchemaRuleComparisonOperator["MatchesRegex"] = "MATCHES_REGEX";
    SchemaRuleComparisonOperator["NotContains"] = "NOT_CONTAINS";
    SchemaRuleComparisonOperator["NotEquals"] = "NOT_EQUALS";
})(SchemaRuleComparisonOperator = exports.SchemaRuleComparisonOperator || (exports.SchemaRuleComparisonOperator = {}));
var SchemaType;
(function (SchemaType) {
    SchemaType["Exclude"] = "Exclude";
    SchemaType["Include"] = "Include";
})(SchemaType = exports.SchemaType || (exports.SchemaType = {}));
var SiteErrorSortField;
(function (SiteErrorSortField) {
    SiteErrorSortField["Created"] = "created";
    SiteErrorSortField["CustomerIntegration"] = "customerIntegration";
    SiteErrorSortField["PiLastName"] = "piLastName";
    SiteErrorSortField["PiOrg"] = "piOrg";
    SiteErrorSortField["ProtocolNumber"] = "protocolNumber";
    SiteErrorSortField["Responsible"] = "responsible";
    SiteErrorSortField["SsuId"] = "ssuId";
    SiteErrorSortField["SsuState"] = "ssuState";
    SiteErrorSortField["SubmittingOrg"] = "submittingOrg";
    SiteErrorSortField["Updated"] = "updated";
})(SiteErrorSortField = exports.SiteErrorSortField || (exports.SiteErrorSortField = {}));
var SitePiNameMatchingType;
(function (SitePiNameMatchingType) {
    SitePiNameMatchingType["Firstlast"] = "FIRSTLAST";
    SitePiNameMatchingType["Lastfirst"] = "LASTFIRST";
})(SitePiNameMatchingType = exports.SitePiNameMatchingType || (exports.SitePiNameMatchingType = {}));
var SiteSuggestionSource;
(function (SiteSuggestionSource) {
    SiteSuggestionSource["Protocol"] = "PROTOCOL";
    SiteSuggestionSource["Site"] = "SITE";
})(SiteSuggestionSource = exports.SiteSuggestionSource || (exports.SiteSuggestionSource = {}));
var SiteXrefSortField;
(function (SiteXrefSortField) {
    SiteXrefSortField["Created"] = "created";
    SiteXrefSortField["CustomerIntegration"] = "customerIntegration";
    SiteXrefSortField["EtmfSiteId"] = "etmfSiteId";
    SiteXrefSortField["EtmfStudyId"] = "etmfStudyId";
    SiteXrefSortField["IsActive"] = "isActive";
    SiteXrefSortField["LastScheduledDate"] = "lastScheduledDate";
    SiteXrefSortField["PiOrg"] = "piOrg";
    SiteXrefSortField["ProId"] = "proId";
    SiteXrefSortField["ProtocolNumber"] = "protocolNumber";
    SiteXrefSortField["SsuId"] = "ssuId";
    SiteXrefSortField["SubmittingOrg"] = "submittingOrg";
})(SiteXrefSortField = exports.SiteXrefSortField || (exports.SiteXrefSortField = {}));
var SortField;
(function (SortField) {
    SortField["Environment"] = "environment";
    SortField["Id"] = "id";
    SortField["IntegrationTypeName"] = "integrationTypeName";
    SortField["IsActive"] = "isActive";
    SortField["MetadataTypeId"] = "metadataTypeId";
    SortField["Name"] = "name";
})(SortField = exports.SortField || (exports.SortField = {}));
var SortOrder;
(function (SortOrder) {
    SortOrder["Asc"] = "ASC";
    SortOrder["Desc"] = "DESC";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var StudyErrorSortField;
(function (StudyErrorSortField) {
    StudyErrorSortField["Created"] = "created";
    StudyErrorSortField["CustomerIntegration"] = "customerIntegration";
    StudyErrorSortField["ProjectState"] = "projectState";
    StudyErrorSortField["SubmittingOrg"] = "submittingOrg";
    StudyErrorSortField["Updated"] = "updated";
})(StudyErrorSortField = exports.StudyErrorSortField || (exports.StudyErrorSortField = {}));
var Ternary;
(function (Ternary) {
    Ternary["False"] = "FALSE";
    Ternary["Null"] = "NULL";
    Ternary["True"] = "TRUE";
})(Ternary = exports.Ternary || (exports.Ternary = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["Completed"] = "Completed";
    TransactionStatus["Error"] = "Error";
    TransactionStatus["Hold"] = "HOLD";
    TransactionStatus["Incomplete"] = "Incomplete";
    TransactionStatus["Pending"] = "Pending";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
