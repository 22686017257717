import { Params } from "./mappingTools";
import resourceGraphql from "./mapping";
import { DocumentNode } from "graphql";
import { DataProviderResult } from "ra-core";

interface BuiltQuery {
  query: DocumentNode;
  variables: Record<string, unknown>;
  parseResponse: (response: {
    data: Record<string, unknown>;
  }) => DataProviderResult;
}
export function buildQuery(
  raFetchType: string,
  resourceName: string,
  params: Params,
): BuiltQuery {
  const resource =
    resourceGraphql[resourceName] && resourceGraphql[resourceName][raFetchType];

  if (!resource) {
    console.error("Missing resource", { raFetchType, resourceName, params });
    throw new Error(
      `Missing resource for raFetchType = ${raFetchType} and resourceName = ${resourceName}`,
    );
  }

  return {
    query: resource.getQuery(params),
    variables: resource.getVariables(params),
    parseResponse: resource.parseResponse,
  };
}

export default function buildQueryFactory(): typeof buildQuery {
  return buildQuery;
}
