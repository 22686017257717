import { Auth } from "@aws-amplify/auth";
import { AuthProvider, UserIdentity } from "react-admin";
import gql from "graphql-tag";

import { Permissions, User } from "./permissions";

import buildClient from "./dataProvider/client";

interface LoginParameters {
  username: string;
  password: string;
  mfaCode?: string;
}

async function prepareSession() {
  const client = buildClient();
  const result = await client.query<{ user: User }>({
    query: gql`
      query {
        user {
          permissions {
            roles
            permissions {
              type
              resource
            }
          }
        }
      }
    `,
  });

  let sessionInfo = {};

  if ((result.errors || []).length > 0) {
    console.error("Failed to retrieve permissions information", result.errors);
  } else if (result.data === null || result.data === undefined) {
    console.error("Failed to retrieve permissions information", result.errors);
  } else {
    sessionInfo = result.data.user;
  }

  window.localStorage.setItem("session", JSON.stringify(sessionInfo));
}

const authProvider: AuthProvider = {
  login: async ({
    username,
    password,
    mfaCode,
  }: LoginParameters): Promise<void> => {
    let user;
    try {
      user = await Auth.signIn(username, password);

      if (mfaCode) {
        user = await Auth.confirmSignIn(
          user, // Return object from Auth.signIn()
          mfaCode,
          "SOFTWARE_TOKEN_MFA",
        );
        if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
          user.challengeName = null;
        }
      }
    } catch (error: any) {
      throw {
        type: "FAILURE",
        error: error.toString(),
      };
    }

    if (user.challengeName) {
      throw {
        type: user.challengeName,
        user,
      };
    } else {
      await prepareSession();
      return;
    }
  },
  getIdentity: async (): Promise<UserIdentity> => {
    return Auth.currentAuthenticatedUser();
  },
  logout: (): Promise<void> => {
    window.localStorage.removeItem("session");
    return Auth.signOut();
  },
  checkAuth: () =>
    Auth.currentAuthenticatedUser()
      .then((result) => {
        console.log("current auth", result.username);
      })
      .catch(() =>
        Promise.reject({
          message: "Login Required",
        }),
      ),
  checkError: (error: { status: number }): Promise<void> => {
    const status = error.status;
    console.log("Failed to query backend", error);
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: async function (): Promise<Permissions> {
    const sessionInfo = window.localStorage.getItem("session");
    let session;

    if (sessionInfo) {
      try {
        session = JSON.parse(sessionInfo);
      } catch (e) {
        console.log("Failed to parse session info from localStorage", e);
      }
    }

    const permissions: Permissions = {
      permissions: [],
      roles: [],
    };

    if (session) {
      permissions.permissions = session.permissions.permissions;
      permissions.roles = session.permissions.roles;
    }

    return permissions;
  },
};

export default authProvider;
